import { DeviceType } from "@/src/app/interface";
import { HandleJavaUsualFetch } from "@/src/utils/fetch";

export async function getCMSDataClient(params: any) {
    const defaultQuery = {
        pageType: params.pageType ?? "home",
        deviceType: DeviceType.MOBILE,
    };

    return HandleJavaUsualFetch.get("/cms/pageData", {
        ...defaultQuery,
        // childPageId: params.sublink,
        // childProductId: params.childProductId,
    });
}
